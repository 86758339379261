import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousels = document.querySelectorAll('.eventsCarousel');

  if (carousels.length > 0) {
    carousels.forEach((carousel) => {
      if (!carousel.swiper) {
        // Vérifie si Swiper n'est pas déjà initié
        new Swiper(carousel, {
          slidesPerView: 1.2,
          spaceBetween: 10,
          breakpoints: {
            640: {
              slidesPerView: 2.2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          },
        });
      }
    });
  }
});
